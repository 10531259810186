<template>
  <v-card max-width="1104px" class="mx-auto" flat>
    <v-card-actions class="mt-5">
      <v-btn large class="pr-4" @click="before">
        <v-icon size="22"> mdi-chevron-left </v-icon> 돌아가기
      </v-btn>
      <v-spacer />
      <v-btn large color="primary" class="pl-4" @click="nextCheck">
        다음 단계 <v-icon size="22"> mdi-chevron-right </v-icon>
      </v-btn>
    </v-card-actions>
    <v-card-text class="pt-15 px-10">
      <v-card flat>
        <div class="d-flex">
          <stepper
            v-if="!$vuetify.breakpoint.smAndUp"
            class="mb-10 mx-auto"
            :steps="3"
            :currentStep="1"
            :label="'파일 선택'"
          />
        </div>
        <div class="d-flex">
          <div>
            <v-card-title class="display-1 font-weight-bold">
              3D 모델 업로드
            </v-card-title>
            <v-card-subtitle class="subtitle-1">
              모델 별 셋팅 지정이 가능합니다
            </v-card-subtitle>
          </div>
          <v-spacer />
          <stepper
            v-if="$vuetify.breakpoint.smAndUp"
            :steps="3"
            :currentStep="1"
            :label="'파일 선택'"
          />
        </div>
        <v-card-actions class="mt-12">
          <order-form-section :formData="formData" />
        </v-card-actions>
      </v-card>
    </v-card-text>
    <v-dialog v-model="noMethodDialog" width="370px" persistent>
      <v-card rounded="lg" class="pa-1">
        <v-card-title class="headline font-weight-bold">
          <feather type="alert-triangle" stroke="orange" class="mr-3" /> 주 의
        </v-card-title>
        <v-card-text class="subtitle-1 font-weight-medium">
          출력 방식과 소재, 색상이 선택되지 않았습니다. <br />
          이대로 진행하시겠습니까?
        </v-card-text>
        <v-card-actions class="pt-0 pb-6">
          <v-row>
            <v-col cols="12" class="py-2">
              <v-btn block @click="next" class="">
                네, 이대로 진행할게요.
              </v-btn>
            </v-col>
            <v-col cols="12" class="py-2">
              <v-btn block @click="noMethodDialog = false" class="primary">
                아니요, 다시 선택할게요.
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import Stepper from "@/components/Assets/Stepper.vue";
import OrderFormSection from "./OrderFormSection.vue";
import _ from "lodash";

export default {
  name: "Order3DFileUpload",
  components: {
    Stepper,
    OrderFormSection,
  },
  props: { data: Object },
  data() {
    return {
      formData: {},
      noMethodDialog: false,
    };
  },
  mounted() {
    this.formData = _.cloneDeep(this.data);
  },
  methods: {
    before() {
      this.$emit("changeStep", 0);
      this.$emit("changeFormData", this.formData);
    },
    nextCheck() {
      if (this.formData.file && this.formData.file[0] == null)
        return this.$toasted.global.error(
          "첨부파일이 없습니다. 다시 확인해주세요"
        );
      if (
        this.formData.file &&
        this.formData.file.findIndex((v) => v.method == 0) != -1
      ) {
        this.noMethodDialog = true;
      } else {
        this.next();
      }
    },
    next() {
      this.$emit("changeFormData", this.formData);
      this.$emit("changeStep", 2);
    },
  },
};
</script>

<style></style>
