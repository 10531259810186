import { readAndCompressImage } from "browser-image-resizer";
import Vue from "vue";

export default {
  async uploadImageFile({
    file,
    path,
    configs = {
      maxSizeMB: 2,
      quality: 1,
      autoRotate: true,
    },
  }) {
    if (file == null) return;
    const storageRef = Vue.prototype.$firebase.storage().ref();
    const config = configs;
    const resizedImage = await readAndCompressImage(file, config);
    // const resizedImage = file;

    let metadata = {
      contentType: "image/*",
    };

    const filePath = path;
    let uploadTask = await storageRef
      .child(filePath)
      .put(resizedImage, metadata);

    const imageURL = await uploadTask.ref.getDownloadURL();

    return {
      filePath: filePath,
      imageURL: imageURL,
    };
  },

  async uploadSTLFile({ file, path }) {
    if (file == null) return;
    const storageRef = Vue.prototype.$firebase.storage().ref();
    let metadata = {
      contentType: "application/octet-stream",
    };
    const filePath = path;
    let uploadTask = await storageRef.child(filePath).put(file, metadata);
    const downloadURL = await uploadTask.ref.getDownloadURL();
    return {
      filePath: filePath,
      downloadURL: downloadURL,
    };
  },
  async uploadFile({ file, path }) {
    if (file == null) return;
    const storageRef = Vue.prototype.$firebase.storage().ref();
    const filePath = path;
    let uploadTask = await storageRef.child(filePath).put(file);
    const downloadURL = await uploadTask.ref.getDownloadURL();
    return {
      filePath: filePath,
      downloadURL: downloadURL,
    };
  },
  async deleteFile(URL) {
    if (URL == null) return;
    await Vue.prototype.$firebase.storage().refFromURL(URL).delete();
    return;
  },
};

/**
 * USE THIS TO CHECK PERCENTAGE
 */
// await uploadTask.on(
//   this.$firebase.storage.TaskEvent.STATE_CHANGED,
//   (snapshot) => {
//     var progress =
//       (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
//     console.log("Upload is " + progress + "% done");
//     switch (snapshot.state) {
//       case this.$firebase.storage.TaskState.PAUSED: // or 'paused'
//         // console.log('Upload is paused')
//         break;
//       case this.$firebase.storage.TaskState.RUNNING: // or 'running'
//         // console.log('Upload is running')
//         break;
//     }
//   },
//   (error) => {
//     switch (error.code) {
//       case "storage/unauthorized":
//         console.error("권한이 없습니다.");
//         break;
//       case "storage/canceled":
//         console.error("취소되었습니다.");
//         break;
//       case "storage/unknown":
//         console.error("문제가 발생했습니다.");
//         break;
//     }
//   },
//   () => {
//     uploadTask.snapshot.ref.getDownloadURL().then(async (url) => {});
//   },
// );
