<template>
  <v-card>
    <v-card-actions>
      <span class="highlight subtitle-1 font-weight-bold onSurface--text">
        3D 모델 뷰어
      </span>
      <v-spacer />
      <!-- <v-btn color="onSurface" icon @click="rotateModel">
        <feather type="chevron-right" />
      </v-btn> -->
      <!-- <v-btn color="onSurface" icon @click="save">
        <feather type="save" />
      </v-btn> -->
      <v-btn color="onSurface" icon @click="$emit('closeDialog')">
        <feather type="x-circle" />
      </v-btn>
    </v-card-actions>
    <v-card-text>
      <div ref="rendererElement" style="height: 480px"></div>
      <div class="mt-2 subtitle-1">
        Model Size: {{ Math.round(dimension.x) }} x
        {{ Math.round(dimension.z) }} x {{ Math.round(dimension.y) }} (mm)
      </div>
      <div class="mt-2 subtitle-1" v-if="$firebase.auth().currentUser">
        100% Infill Volume: {{ Math.round(dimension.volume) }} (mm^3) =
        {{ Math.round(dimension.volume * 0.00124) + 4 }} (g) =
        {{ (Math.round(dimension.volume * 0.00124) + 4) * 35 }} (won)
      </div>
      <div class="mt-2 subtitle-1" v-if="$firebase.auth().currentUser">
        20% Infill Volume:
        {{
          Math.round((dimension.volume - dimension.area) * 0.2 + dimension.area)
        }}
        (mm^3) =
        {{
          Math.round(
            ((dimension.volume - dimension.area) * 0.2 + dimension.area) *
              0.00124
          )
        }}
        (g)=
        {{
          Math.round(
            ((dimension.volume - dimension.area) * 0.2 + dimension.area) *
              0.00124
          ) * 35
        }}
        (won)
      </div>
      <div class="mt-2 subtitle-1">
        Area: {{ Math.round(dimension.area) }} (mm^2)
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { STLLoader } from "three/examples/jsm/loaders/STLLoader";
import { STLExporter } from "three/examples/jsm/exporters/STLExporter";
import firestorageUtils from "@/utils/firestorage";

export default {
  props: { data: Object, file: File, dialog: Boolean },
  data() {
    return {
      scene: null, // Will be initialized in initializeScene
      camera: null, // Will be initialized in initializeScene
      renderer: null, // Will be initialized in initializeScene
      cameraLight: null, // Will be initialized in addModelToScene
      material: new THREE.MeshPhongMaterial({ color: 0xaecdff }), // Set model color to blue
      controls: null, // Will be initialized in initializeScene
      dimension: { x: 0, y: 0, z: 0, volume: 0 },
    };
  },
  async mounted() {
    this.$nextTick(() => {
      this.show3dModel();
    });
  },
  watch: {
    dialog(val) {
      val ? this.show3dModel() : this.resetScene();
    },
  },
  methods: {
    async show3dModel() {
      let model = null;
      if (this.file) {
        model = this.file;
      } else {
        await fetch(this.data.downloadURL)
          .then((response) => response.blob())
          .then((blob) => {
            model = blob;
            console.log(blob);
          })
          .catch((err) => console.error(err));
      }
      const reader = new FileReader();
      reader.onload = (event) => {
        const loader = new STLLoader();
        const geometry = loader.parse(event.target.result);
        geometry.rotateX(Math.PI / -2);
        this.initializeScene();
        this.addModelToScene(geometry, model);
      };
      reader.readAsArrayBuffer(model);
    },
    initializeScene() {
      this.scene = new THREE.Scene();
      this.scene.background = new THREE.Color(0xffffff); // Set background color to white
      const aspectRatio =
        this.$refs.rendererElement.offsetWidth /
        this.$refs.rendererElement.offsetHeight;
      this.camera = new THREE.PerspectiveCamera(75, aspectRatio, 0.1, 2000);
      this.renderer = new THREE.WebGLRenderer({ antialias: true });
      this.renderer.setSize(
        this.$refs.rendererElement.offsetWidth,
        this.$refs.rendererElement.offsetHeight
      );
      this.$refs.rendererElement.innerHTML = "";
      this.$refs.rendererElement.appendChild(this.renderer.domElement);
      let gridHelper = new THREE.GridHelper(300, 30);
      gridHelper.position.set(0, 0, 0);
      this.scene.add(gridHelper);
      this.controls = new OrbitControls(this.camera, this.renderer.domElement);
    },
    signedVolumeOfTriangle(p1, p2, p3) {
      return p1.dot(p2.cross(p3)) / 6.0;
    },
    getVolume(geometry) {
      let position = geometry.attributes.position;
      let faces = position.count / 3;
      let sum = 0;
      let p1 = new THREE.Vector3(),
        p2 = new THREE.Vector3(),
        p3 = new THREE.Vector3();
      for (let i = 0; i < faces; i++) {
        p1.fromBufferAttribute(position, i * 3 + 0);
        p2.fromBufferAttribute(position, i * 3 + 1);
        p3.fromBufferAttribute(position, i * 3 + 2);
        sum += this.signedVolumeOfTriangle(p1, p2, p3);
      }
      return sum;
    },
    getArea(geometry) {
      let position = geometry.attributes.position;
      let faces = position.count / 3;
      let sum = 0;
      let p1 = new THREE.Vector3(),
        p2 = new THREE.Vector3(),
        p3 = new THREE.Vector3();
      for (let i = 0; i < faces; i++) {
        p1.fromBufferAttribute(position, i * 3 + 0);
        p2.fromBufferAttribute(position, i * 3 + 1);
        p3.fromBufferAttribute(position, i * 3 + 2);
        sum += this.areaOfTriangle(p1, p2, p3);
      }
      return sum;
    },
    areaOfTriangle(p1, p2, p3) {
      const v0 = new THREE.Vector3().subVectors(p2, p1);
      const v1 = new THREE.Vector3().subVectors(p3, p1);
      const cross = new THREE.Vector3().crossVectors(v0, v1);
      return cross.length() * 0.5;
    },
    addModelToScene(geometry, file) {
      const parent = new THREE.Object3D(); // Create a parent object
      const mesh = new THREE.Mesh(geometry, this.material);
      parent.add(mesh); // Add the mesh to the parent object
      const geometryCenter = new THREE.Vector3();
      geometry.computeBoundingBox();
      geometry.boundingBox.getCenter(geometryCenter);

      geometry.translate(
        -geometryCenter.x,
        -geometryCenter.y,
        -geometryCenter.z
      );

      // Calculate file dimensions after the mesh is added to the scene
      const fileBox = new THREE.Box3().setFromObject(parent);
      const center = fileBox.getCenter(new THREE.Vector3());

      parent.position.sub(center);
      const size = fileBox.getSize(new THREE.Vector3()).length();
      this.dimension = fileBox.getSize(new THREE.Vector3()); // Get file dimensions
      this.dimension.volume = this.getVolume(geometry);
      this.dimension.area = this.getArea(geometry);
      file.dimension = {
        x: this.dimension.x,
        y: this.dimension.z,
        z: this.dimension.y,
      };

      const lightColor = 0xffffff;
      const topLight = new THREE.DirectionalLight(lightColor, 0.4);
      topLight.position.set(0, 100, 0);
      topLight.castShadow = true;
      this.scene.add(topLight);
      this.cameraLight = new THREE.DirectionalLight(lightColor, 0.6);
      this.cameraLight.castShadow = true;
      this.scene.add(this.cameraLight);
      parent.position.set(0, file.dimension.z / 2, 0); // Y축만 모델의 두께만큼 올립니다.

      // parent.position.sub(center);
      // parent.position.y += file.dimension.z / 2;

      this.scene.add(parent);

      // Adjust camera's position
      this.camera.position.set(0, size, size);
      this.cameraLight.position.copy(this.camera.position);
      this.controls.target.set(0, file.dimension.y / 2, 0);
      this.controls.update();

      this.addAxisHelpers();
      this.animate();
    },
    addAxisHelpers() {
      // Create line geometry
      const XaxisGeometry = new THREE.BufferGeometry().setFromPoints([
        new THREE.Vector3(-150, 0, 0),
        new THREE.Vector3(150, 0, 0),
      ]);

      const YaxisGeometry = new THREE.BufferGeometry().setFromPoints([
        new THREE.Vector3(0, 0, 0),
        new THREE.Vector3(0, 150, 0),
      ]);

      const ZaxisGeometry = new THREE.BufferGeometry().setFromPoints([
        new THREE.Vector3(0, 0, -150),
        new THREE.Vector3(0, 0, 150),
      ]);

      // Create line material
      const XaxisMaterial = new THREE.LineBasicMaterial({
        color: 0xff0000,
        linewidth: 4,
      }); // Red for X axis
      const YaxisMaterial = new THREE.LineBasicMaterial({
        color: 0x0000ff,
        linewidth: 3,
      }); // Green for Y axis
      const ZaxisMaterial = new THREE.LineBasicMaterial({
        color: 0x00ff00,
        linewidth: 4,
      }); // Blue for Z axis

      // Create line
      const XaxisLine = new THREE.Line(XaxisGeometry, XaxisMaterial);
      const YaxisLine = new THREE.Line(YaxisGeometry, YaxisMaterial);
      const ZaxisLine = new THREE.Line(ZaxisGeometry, ZaxisMaterial);

      this.scene.add(XaxisLine);
      this.scene.add(YaxisLine);
      this.scene.add(ZaxisLine);
    },
    animate() {
      const animate = () => {
        if (this.camera) {
          // Only animate if camera is not null
          if (document.hidden) {
            // If the tab is not active, use requestIdleCallback
            requestIdleCallback(animate);
          } else {
            // If the tab is active, use requestAnimationFrame
            requestAnimationFrame(animate);
          }
          this.cameraLight.position.copy(this.camera.position);
          this.renderer.render(this.scene, this.camera);
        }
      };
      animate();
    },
    resetScene() {
      while (this.scene.children.length > 0) {
        this.scene.remove(this.scene.children[0]);
      }
      this.renderer.dispose();
      this.renderer = null;
      this.camera = null;
      this.controls.dispose();
      this.controls = null;
      this.$refs.rendererElement.innerHTML = "";
    },
    rotateModel() {
      if (this.scene && this.scene.children.length > 0) {
        this.scene.children.forEach((child) => {
          if (child instanceof THREE.Object3D) {
            child.children.forEach((mesh) => {
              if (mesh instanceof THREE.Mesh) {
                mesh.rotation.y += Math.PI / 4; // 45도 회전
              }
            });
          }
        });
      }
    },
    async save() {
      const exporter = new STLExporter();
      let meshToExport = null;
      this.scene.traverse((child) => {
        if (child instanceof THREE.Mesh) {
          meshToExport = child;
        }
      });
      const exportResult = exporter.parse(meshToExport, { binary: true });
      const blob = new Blob([exportResult], {
        type: "application/octet-stream",
      });

      // Extract the file name and version
      const pathParts = this.data.filePath.split("/");
      let [baseName, ext] = pathParts.pop().split(".");
      const versionMatch = baseName.match(/_V(\d+)$/);

      // Update the version or append _V1
      baseName = versionMatch
        ? baseName.replace(
            versionMatch[0],
            `_V${parseInt(versionMatch[1]) + 1}`
          )
        : `${baseName}_V1`;

      pathParts.push(`${baseName}.${ext}`);
      const newFilePath = pathParts.join("/");

      // Upload the file
      const result = await firestorageUtils.uploadFile({
        file: blob,
        path: newFilePath,
      });

      this.data.filePath = newFilePath;
      this.data.downloadURL = result.downloadURL;

      console.log(this.data.downloadURL);
    },
  },
};
</script>
