var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{class:[_vm.$vuetify.breakpoint.smAndUp ? 'pt-5 px-0' : 'pt-4 px-2'],attrs:{"width":"100%","rounded":"lg","flat":""},on:{"drop":function($event){$event.preventDefault();return _vm.onFileDrop($event)},"dragover":function($event){$event.preventDefault();_vm.dragover = true},"dragenter":function($event){$event.preventDefault();_vm.dragover = true},"dragleave":function($event){$event.preventDefault();_vm.dragover = false}}},[_c('v-row',[_vm._l((_vm.formData.file),function(item,fileIndex){return [_c('v-col',{key:fileIndex,attrs:{"cols":"12","sm":"6","md":"4","lg":"3"}},[_c('v-card',{attrs:{"outlined":"","height":"100%","rounded":"lg"}},[_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","x-small":""},on:{"click":function($event){return _vm.copyItem(fileIndex, item)}}},[_c('feather',{attrs:{"size":"18","type":"copy","stroke":"black"}})],1),_c('v-btn',{staticClass:"ml-3",attrs:{"icon":"","x-small":""},on:{"click":function($event){return _vm.deleteItem(fileIndex, item)}}},[_c('feather',{attrs:{"size":"18","type":"trash","stroke":"red"}})],1)],1),_c('v-card-text',{staticClass:"pt-1 px-2"},[_c('file-viewer',{attrs:{"file":item}}),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"subtitle-2 font-weight-regular onSurface--text",domProps:{"textContent":_vm._s(item.name)}})])],1),_c('v-row',{staticClass:"mt-2"},[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"4"}},[_c('span',{staticClass:"subtitle-2 font-weight-regular",domProps:{"textContent":_vm._s('방식')}})]),_c('v-col',{staticClass:"d-flex pt-0",attrs:{"cols":"8"}},[_c('v-menu',{attrs:{"rounded":"lg","bottom":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"pl-2 pr-0",attrs:{"text":"","height":"20px","outlined":""}},'v-btn',attrs,false),on),[_c('span',{staticClass:"subtitle-2 font-weight-regular onSurface--text overflow-x-hidden",domProps:{"textContent":_vm._s(_vm.getOption(item).method.split('(')[0])}}),_c('v-icon',{staticClass:"ml-2",attrs:{"size":"20"}},[_vm._v(" mdi-chevron-down ")])],1)]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},_vm._l((_vm.printOption),function(printOption,index){return _c('v-list-item',{key:index,on:{"click":function($event){return _vm.methodChange(fileIndex, index)}}},[_c('v-list-item-title',[_c('span',{staticClass:"ml-2 subtitle-2 font-weight-regular onSurface--text",domProps:{"textContent":_vm._s(printOption.method)}})])],1)}),1)],1)],1)],1),_c('v-row',{staticClass:"mt-0"},[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"4"}},[_c('span',{staticClass:"subtitle-2 font-weight-regular",domProps:{"textContent":_vm._s('소재')}})]),_c('v-col',{staticClass:"d-flex pt-0",attrs:{"cols":"8"}},[_c('span',{staticClass:"subtitle-2 font-weight-regular onSurface--text"},[_c('v-menu',{attrs:{"rounded":"lg","bottom":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"pl-2 pr-0",attrs:{"text":"","height":"20px","min-width":"10px","outlined":""}},'v-btn',attrs,false),on),[_c('span',{staticClass:"subtitle-2 font-weight-regular onSurface--text",domProps:{"textContent":_vm._s(
                            _vm.printOption[item.method].material[
                              item.material
                            ].name.split('(')[0]
                          )}}),_c('v-icon',{staticClass:"ml-2",attrs:{"size":"20"}},[_vm._v(" mdi-chevron-down ")])],1)]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},_vm._l((_vm.printOption[
                          item.method
                        ].material),function(materialList,index){return _c('v-list-item',{key:index,on:{"click":function($event){return _vm.materialChange(fileIndex, index)}}},[_c('v-list-item-title',[_c('span',{staticClass:"ml-2 subtitle-2 font-weight-regular onSurface--text",domProps:{"textContent":_vm._s(materialList.name)}})])],1)}),1)],1)],1)])],1),_c('v-row',{staticClass:"mt-0"},[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"4"}},[_c('span',{staticClass:"subtitle-2 font-weight-regular",domProps:{"textContent":_vm._s('색상')}})]),_c('v-col',{staticClass:"d-flex pt-0",attrs:{"cols":"8"}},[_c('span',{staticClass:"subtitle-2 font-weight-regular onSurface--text"},[_c('v-menu',{attrs:{"rounded":"lg","bottom":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"pl-2 pr-0",attrs:{"text":"","height":"20px","min-width":"10px","outlined":""}},'v-btn',attrs,false),on),[_c('span',{staticClass:"subtitle-2 font-weight-regular onSurface--text",domProps:{"textContent":_vm._s(_vm.getOption(item).color.name.split('(')[0])}}),_c('v-icon',{staticClass:"ml-2",attrs:{"size":"20"}},[_vm._v(" mdi-chevron-down ")])],1)]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},_vm._l((_vm.printOption[item.method]
                          .material[item.material].color),function(colorList,index){return _c('v-list-item',{key:index,on:{"click":function($event){item.color = index}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"mr-3",staticStyle:{"border":"1px solid grey","border-radius":"50%"},attrs:{"size":"14","color":colorList.color}},[_vm._v(" mdi-circle ")]),_c('span',{staticClass:"ml-2 subtitle-2 font-weight-regular onSurface--text",domProps:{"textContent":_vm._s(colorList.name)}})],1)],1)}),1)],1)],1)])],1),_c('v-row',{staticClass:"mt-0"},[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"4"}},[_c('span',{staticClass:"subtitle-2 font-weight-regular",domProps:{"textContent":_vm._s('수량')}})]),_c('v-col',{staticClass:"d-flex pt-0",attrs:{"cols":"8"}},[_c('span',{staticClass:"subtitle-2 font-weight-regular onSurface--text"},[_c('v-menu',{attrs:{"rounded":"lg","bottom":"","offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                          var on = ref.on;
                          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"px-2",attrs:{"text":"","height":"20px","min-width":"10px","outlined":""}},'v-btn',attrs,false),on),[_c('span',{staticClass:"subtitle-2 font-weight-regular onSurface--text",domProps:{"textContent":_vm._s(item.quantity.toLocaleString() + 'EA')}})])]}}],null,true)},[_c('v-card',{attrs:{"width":"160px"}},[_c('v-card-text',[_c('v-text-field',{attrs:{"label":'작업 수량',"outlined":"","type":"number","hide-details":"","dense":""},model:{value:(item.quantity),callback:function ($$v) {_vm.$set(item, "quantity", $$v)},expression:"item.quantity"}})],1)],1)],1)],1)])],1)],1)],1)],1)]}),_c('v-col',{attrs:{"cols":"12","sm":_vm.formData.file && !_vm.formData.file[0] ? '12' : '6',"md":_vm.formData.file && !_vm.formData.file[0] ? '12' : '4',"lg":_vm.formData.file && !_vm.formData.file[0] ? '12' : '3'}},[_c('v-card',{staticStyle:{"border":"3px dashed #0090ff"},style:({ backgroundColor: _vm.dragover ? 'rgba(0, 0, 0, 0.05)' : '' }),attrs:{"outlined":"","height":"100%","min-height":"220px","rounded":"lg"},on:{"click":function($event){return _vm.$refs.uploader.click()}}},[_c('input',{ref:"uploader",staticClass:"d-none",attrs:{"type":"file","multiple":""},on:{"change":_vm.onFileInput}}),_c('v-card-text',{staticClass:"d-flex text-center",staticStyle:{"height":"100%"}},[_c('div',{staticClass:"my-auto mx-auto"},[_c('v-icon',{staticClass:"d-flex",attrs:{"size":"40","color":"primary"}},[_vm._v(" mdi-cloud-upload ")]),_c('div',[_c('span',{staticClass:"subtitle-2 font-weight-bold primary--text",domProps:{"textContent":_vm._s('추가 파일 업로드')}})]),_c('div',[_c('span',{staticClass:"mx-auto caption text-center",domProps:{"innerHTML":_vm._s(
                  '견적 접수 전 파일은' + '<br/>' + '서버에 저장되지 않습니다'
                )}})])],1)])],1)],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }