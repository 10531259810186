export default {
  // 회원 정보
  // levels = customClaims와 연결되는 권한 정보
  // userClass = 개인 학생 기업 구분정보
  // userInterface = 회원 DB 저장 type
  levels: [
    { text: "개발자", value: 0, icon: "tool", color: "orange" },
    {
      text: "관리자",
      value: 1,
      icon: "shield",
      color: "var(--v-primary-base)",
    },
    { text: "운영자", value: 2, icon: "user-plus", color: "green" },
    { text: "회원", value: 3, icon: "user", color: "black" },
    { text: "손님", value: 4, icon: "user", color: "black" },
  ],
  userClass: [
    { text: "개인회원", icon: "mdi-account", color: "black" },
    { text: "학생회원", icon: "mdi-account", color: "black" },
    { text: "기업회원", icon: "mdi-account", color: "black" },
  ],
  userInterface: {
    photoURL:
      "https://firebasestorage.googleapis.com/v0/b/madeall.appspot.com/o/Assets%2FUsers%2FDefaultProfileImage.svg?alt=media&token=c5b5bb4c-a225-47b9-a6a7-d980364debef",
    uid: "",
    email: "",
    emailVerified: false,
    nickName: "",
    displayName: "",
    disabled: true,
    createdAt: "",
    updatedAt: "",
    visitedAt: "",
    visitCount: 0,
    level: 3,
    countryCode: "",
    phoneNumber: "",
    makerInfo: { makerId: "", makerSpace: "" },
    companyInfo: {
      companyId: "",
      companyName: "",
      cheifName: "",
      email: "",
    },
    agree: { rule: false, userInfo: false, marketing: true },
    adminInfo: "",
    // 통계
  },
  // 주문 관련 정보
  // orderFormInterface = 주문서 DB 저장 type
  orderFormInterface: {
    // 기본 정보
    id: "",
    createdAt: new Date(),
    // 주문자 정보
    uid: "",
    email: "",
    displayName: "",
    phoneNumber: "",
    userClass: 0,
    schoolInfo: {},
    companyInfo: {},
    // 주문 정보
    purpose: "", // 견적 제목
    environment: "", // 사용 환경
    memo: "", // 참고 사항
    dueAt: "", // 희망 납기
    workspace: "메이드올 대구지사", // 작업 장소
    file: [],
    // 작업 정보
    status: 0, // 진행 단계
    statusChanged: [], // 진행 단계 변경 이력
    shipAt: "", // 출고(예정)일
    confirmed: false,
    confirmedAt: null,
    updatedAt: new Date(),
    finishedAt: null,
    productCost: 3500,
    workspaceCost: 3500,
    paymentMethod: 0,
    shippingMethod: 0,
    printMemo: "",
    printFile: [],
    docs: {
      orderDocPath: "",
      orderDocDownloadUrl: "",
      quoteFilePath: "",
      quoteDownloadUrl: "",
      print: false,
    },
  },
  printOption: [
    {
      method: "상담 후 결정",
      material: [{ name: "미정", color: [{ name: "미정", color: "#FFFFFF" }] }],
    },
    {
      method: "FDM (일반 필라멘트 방식)",
      material: [
        {
          name: "PLA (저렴하고 깔끔한 일반 소재)",
          color: [
            { name: "White", color: "#FFFFFF" },
            { name: "Natural(반투명)", color: "#FDF5E6" },
            { name: "Black", color: "#000000" },
            { name: "Gray", color: "#808080" },
            { name: "Silver", color: "#C0C0C0" },
            { name: "DarkGreen", color: "#008716" },
            { name: "Green", color: "#00CE00" },
            { name: "WarmBeige", color: "#FFE4CB" },
            { name: "Yellow", color: "#FFFF00" },
            { name: "Orange", color: "#FF5D21" },
            { name: "Red", color: "#EA0000" },
            { name: "SoftPink", color: "#FFB6C1" },
            { name: "Pink", color: "#FF1493" },
            { name: "Violet", color: "#7818D0" },
            { name: "SkyBlue", color: "#8AD2FF" },
            { name: "Blue", color: "#0038FD" },
            { name: "Navy", color: "#000080" },
            { name: "Gold", color: "#FFBF1D" },
            { name: "Copper", color: "#AF2626" },
            { name: "Brown", color: "#854831" },
          ],
        },
        {
          name: "PETG (내충격성 eg.기계 부품)",
          color: [
            { name: "White", color: "#FFFFFF" },
            { name: "Black", color: "#000000" },
          ],
        },
        {
          name: "ABS (내열성 eg.차량 내부)",
          color: [
            { name: "White", color: "#FFFFFF" },
            { name: "Black", color: "#000000" },
            { name: "Red", color: "#EA0000" },
          ],
        },
        {
          name: "TPU (말랑한 재질)",
          color: [
            { name: "White", color: "#FFFFFF" },
            { name: "Black", color: "#000000" },
            { name: "Red", color: "#EA0000" },
            { name: "Blue", color: "#0038FD" },
          ],
        },
      ],
    },
    {
      method: "SLA (레진, FDM 금액의 약 10배)",
      material: [
        {
          name: "ABS-Like (산업용)",
          color: [
            { name: "Ivory", color: "#fcfced" },
            { name: "Black", color: "#000000" },
          ],
        },
        {
          name: "아크릴레진 (피규어용)",
          color: [{ name: "Random", color: "#FFFFFF" }],
        },
      ],
    },
  ],
  orderStatusItem: [
    { title: "주문 접수", icon: "upload-cloud" },
    { title: "상담 중", icon: "headphones" },
    { title: "상담 완료", icon: "user-check" },
    { title: "결제 확인", icon: "credit-card", color: "orange" },
    { title: "작업 진행", icon: "tool", color: "green" },
    { title: "작업 완료", icon: "package", color: "var(--v-primary-base)" },
    { title: "완료", icon: "send" },
  ],
  orderPayment: [
    { title: "스마트스토어", icon: "credit-card", color: "green" },
    { title: "현금 결제", icon: "dollar-sign", color: "orange" },
    { title: "세금계산서(선불)", icon: "file-text" },
    { title: "세금계산서(후불)", icon: "file-text" },
  ],
  shippingMethod: [
    {
      title: "CJ대한통운",
      icon: "package",
      color: "blue",
      price: 3500,
    },
    { title: "우체국 등기", icon: "mail", color: "red", price: 4500 },
    {
      title: "우체국 택배",
      icon: "package",
      color: "red",
      price: 5500,
    },
    { title: "고속버스탁송", icon: "truck", price: 20000 },
    { title: "오토바이퀵", icon: "user", price: 0 },
    { title: "방문수령", icon: "user", price: 0 },
    { title: "직접전달", icon: "user", price: 0 },
    { title: "무료(합)배송", icon: "user", price: 0 },
  ],
  makerSpace: [
    "메이드올 대구지사",
    "경희대학교 국제캠퍼스",
    "더엠제이",
    "쓰리디솔루션",
  ],
  schoolList: ["경희대학교 국제캠퍼스"],
  portfolioInterface: {
    id: "",
    title: "",
    content: [],
    contentImages: [],
    imageURL: "",
    createdAt: new Date(),
    filePath: "",
    options: {
      cost: "",
      method: "",
      material: "",
      postprocess: "",
      time: "",
    },
    updatedAt: new Date(),
    uid: "",
    locked: false,
  },
  pressInterface: {
    id: "",
    title: "",
    subtitle: "",
    url: "",
    imageURL: "",
    locked: true,
    filePath: "",
    createdAt: new Date(),
    updatedAt: new Date(),
    uid: "",
  },
};
