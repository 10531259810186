<template>
  <v-card max-width="1104px" class="mx-auto" flat>
    <v-card-actions class="mt-5">
      <v-btn large class="pr-4" @click="before">
        <v-icon size="22"> mdi-chevron-left </v-icon> 돌아가기
      </v-btn>
      <v-spacer />
      <v-btn large color="primary" class="pl-4" @click="next">
        다음 단계 <v-icon size="22"> mdi-chevron-right </v-icon>
      </v-btn>
    </v-card-actions>
    <v-card-text class="pt-15 px-10">
      <v-card flat>
        <div class="d-flex">
          <stepper
            v-if="!$vuetify.breakpoint.smAndUp"
            class="mb-10 mx-auto"
            :steps="3"
            :currentStep="2"
            :label="'주문 정보'"
          />
        </div>
        <div class="d-flex">
          <div>
            <v-card-title class="display-1 font-weight-bold">
              주문 정보입력
            </v-card-title>
            <v-card-subtitle class="subtitle-1">
              빠짐없이 정확하게 작성해 주세요
            </v-card-subtitle>
          </div>
          <v-spacer />
          <stepper
            v-if="$vuetify.breakpoint.smAndUp"
            :steps="3"
            :currentStep="2"
            :label="'주문 정보'"
          />
        </div>
        <v-card-text class="mt-12 onSurface--text">
          <v-row
            style="border-top: 2px solid black; border-bottom: 2px solid black"
            :class="$vuetify.breakpoint.smAndUp ? 'mx-5 py-10' : 'py-10'"
          >
            <v-col md="4" cols="12">
              <span class="headline font-weight-bold" v-text="'주문 정보'" />
            </v-col>
            <v-col md="8" cols="12">
              <v-row>
                <v-col
                  sm="3"
                  cols="12"
                  align-self="center"
                  :class="$vuetify.breakpoint.xsAndDown ? 'pb-0 pt-5' : ''"
                >
                  주문 제목 <span class="error--text" v-text="'*'" />
                </v-col>
                <v-col sm="9" cols="12">
                  <v-text-field
                    dense
                    hide-details
                    v-model="formData.purpose"
                    class="subtitle-2 font-weight-regular"
                    placeholder="주문서 식별이 가능한 제목을 작성해주세요."
                  />
                </v-col>
                <v-col
                  sm="3"
                  cols="12"
                  align-self="center"
                  :class="$vuetify.breakpoint.xsAndDown ? 'pb-0 pt-5' : ''"
                >
                  사용 환경 <span class="error--text" v-text="'*'" />
                </v-col>
                <v-col sm="9" cols="12">
                  <v-text-field
                    dense
                    hide-details
                    v-model="formData.environment"
                    class="subtitle-2 font-weight-regular"
                    placeholder="사용 환경에 따라 소재를 추천해 드립니다."
                  />
                </v-col>
                <v-col
                  sm="3"
                  cols="12"
                  align-self="center"
                  :class="$vuetify.breakpoint.smAndDown ? 'pb-0 pt-5' : ''"
                >
                  참고 사항
                </v-col>
                <v-col sm="9" cols="12">
                  <v-text-field
                    dense
                    hide-details
                    v-model="formData.memo"
                    class="subtitle-2 font-weight-regular"
                    placeholder="작업에 필요한 추가 정보를 작성해주세요."
                  />
                </v-col>
                <v-col
                  sm="3"
                  cols="12"
                  align-self="center"
                  :class="$vuetify.breakpoint.smAndDown ? 'pb-0 pt-5' : ''"
                >
                  작업 장소
                </v-col>
                <v-col sm="9" cols="12">
                  <v-autocomplete
                    v-model="formData.workspace"
                    class="subtitle-2 font-weight-regular"
                    :items="makerSpace"
                    cache-items
                    single-line
                    hide-no-data
                    hide-details
                    dense
                    label="작업 장소를 선택하세요."
                  />
                </v-col>
                <v-col
                  sm="3"
                  cols="12"
                  align-self="center"
                  :class="$vuetify.breakpoint.smAndDown ? 'pb-0 pt-5' : ''"
                >
                  희망 납기
                </v-col>
                <v-col sm="9" cols="12">
                  <v-menu
                    v-model="datepicker"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition "
                    class="rounded-lg"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="formData.dueAt"
                        class="subtitle-2 font-weight-regular"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        hide-details
                        dense
                        offset-y
                        :menu-props="{ offsetY: true }"
                        placeholder="물건을 수령해야 하는 기한을 입력해주세요."
                      />
                    </template>
                    <v-date-picker
                      v-model="formData.dueAt"
                      @input="datepicker = false"
                      show-current
                      locale="ko"
                      flat
                      color="primary"
                    />
                  </v-menu>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row
            style="border-top: 2px solid black; border-bottom: 2px solid black"
            class="mt-15 py-10"
            :class="$vuetify.breakpoint.smAndUp ? 'mx-5' : ''"
          >
            <v-col md="4" cols="12">
              <span class="headline font-weight-bold" v-text="'회원 정보'" />
            </v-col>
            <v-col md="8" cols="12">
              <v-row>
                <v-col
                  sm="3"
                  cols="12"
                  align-self="center"
                  :class="$vuetify.breakpoint.smAndDown ? 'pb-0 pt-5' : ''"
                >
                  성 함 <span class="error--text" v-text="'*'" />
                </v-col>
                <v-col sm="9" cols="12">
                  <div v-if="$store.getters['auth/user/GET_USER']">
                    {{ formData.displayName }}
                  </div>
                  <v-text-field
                    v-else
                    dense
                    hide-details
                    v-model="formData.displayName"
                    class="subtitle-2 font-weight-regular"
                    placeholder="결제 내역 연동이 가능한 실명을 입력해 주세요."
                    :rules="[(v) => /^[가-힣]{2,5}$/.test(v)]"
                  />
                </v-col>
                <v-col
                  sm="3"
                  cols="12"
                  align-self="center"
                  :class="$vuetify.breakpoint.smAndDown ? 'pb-0 pt-5' : ''"
                >
                  연락처 <span class="error--text" v-text="'*'" />
                </v-col>
                <v-col sm="9" cols="12">
                  <div v-if="$store.getters['auth/user/GET_USER']">
                    {{ formData.phoneNumber }}
                  </div>
                  <v-text-field
                    v-else
                    dense
                    v-model="formData.phoneNumber"
                    hide-details
                    class="subtitle-2 font-weight-regular"
                    placeholder="작업 진행상황을 안내받을 연락처를 남겨주세요."
                    :rules="[(v) => /^[0-9|]+$/.test(v)]"
                  />
                </v-col>
                <v-col
                  sm="3"
                  cols="12"
                  align-self="center"
                  :class="$vuetify.breakpoint.smAndDown ? 'pb-0 pt-5' : ''"
                >
                  구 분
                </v-col>
                <v-col sm="9" cols="12">
                  {{ userData ? "회원" : "비회원" }}
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-card-text>
  </v-card>
</template>

<script>
import Stepper from "@/components/Assets/Stepper.vue";
import _ from "lodash";
export default {
  components: { Stepper },
  props: { userData: Object, data: Object },
  data() {
    return {
      formData: {},
      makerSpace: ["메이드올 대구지사", "경희대학교 국제캠퍼스"],
      datepicker: false,
    };
  },
  watch: {
    formData: {
      handler() {
        this.formData.phoneNumber =
          this.formData.phoneNumber &&
          this.formData.phoneNumber.replace(/[^0-9]/g, "");
      },
      deep: true,
    },
  },
  mounted() {
    this.formData = _.cloneDeep(this.data);
    this.formData.uid = this.userData.uid;
    this.formData.email = this.userData.email;
    this.formData.displayName = this.userData.displayName;
    this.formData.phoneNumber = this.userData.phoneNumber;
    this.formData.schoolInfo = this.userData.schoolInfo;
    this.formData.companyInfo = this.userData.companyInfo;
  },
  methods: {
    before() {
      this.$emit("changeStep", 1);
    },
    next() {
      if (this.formData.purpose == "")
        return this.$toasted.global.error("제목을 입력하세요");
      if (this.formData.environment == "")
        return this.$toasted.global.error("환경을 입력하세요");
      if (this.formData.displayName == "")
        return this.$toasted.global.error("성함을 입력하세요");
      if (this.formData.phoneNumber == "")
        return this.$toasted.global.error("연락처를 입력하세요");
      if (this.formData.phoneNumber.length != 11) {
        this.formData.phoneNumber = this.formData.phoneNumber.replace(
          /[^0-9]/g,
          ""
        );
        this.$toasted.global.error("연락처를 올바르게 입력해주세요");
        return;
      }
      if (
        this.formData.workspace == "경희대학교 국제캠퍼스" &&
        this.userData.makerInfo.makerSpace != "경희대학교 국제캠퍼스"
      )
        return this.$toasted.global.error(
          "특정 메이커스페이스는 소속 회원만 이용할 수 있습니다. 마이페이지에서 메이커 정보를 입력해 주세요"
        );
      window.ChannelIO(
        "boot",
        {
          pluginKey: "e6541c37-05a3-4119-8e7f-88b9c21a509c",
          memberId: this.formData.uid ? this.formData.uid : undefined,
          profile: {
            name: this.formData.displayName,
            mobileNumber: "+82" + this.formData.phoneNumber.slice(1),
          },
        },
        function onBoot(error, user) {
          if (error) {
            console.error(error);
          } else {
            window.channelUser = user;
          }
        }
      );
      this.$emit("changeFormData", this.formData);
      this.$emit("changeStep", 3);
    },
  },
};
</script>

<style></style>
