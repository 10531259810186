<template>
  <v-card max-width="1104px" class="mx-auto" flat>
    <v-card-actions class="mt-5">
      <v-btn large class="pr-4" @click="$router.push('/page')">
        <v-icon size="22"> mdi-chevron-left </v-icon> 돌아가기
      </v-btn>
      <v-spacer />
      <v-btn large color="primary" class="pl-4" @click="next">
        다음 단계 <v-icon size="22"> mdi-chevron-right </v-icon>
      </v-btn>
    </v-card-actions>
    <v-card-text class="pt-15 px-10">
      <v-card flat>
        <div class="d-flex">
          <stepper
            v-if="!$vuetify.breakpoint.smAndUp"
            class="mb-10 mx-auto"
            :steps="3"
            :currentStep="0"
            :label="'도면 여부'"
          />
        </div>
        <div class="d-flex">
          <div>
            <v-card-title class="display-1 font-weight-bold">
              3D모델이 있나요?
            </v-card-title>
            <v-card-subtitle class="subtitle-1">
              도면 여부에 따라 작업 과정이 달라집니다.
            </v-card-subtitle>
          </div>
          <v-spacer />
          <stepper
            v-if="$vuetify.breakpoint.smAndUp"
            :steps="3"
            :currentStep="0"
            :label="'도면 여부'"
          />
        </div>
        <v-card-actions class="mt-12">
          <v-row>
            <v-col>
              <v-card
                class="mx-5 pa-4"
                rounded="lg"
                height="240px"
                min-width="250px"
                elevation="4"
                @click="noFile()"
              >
                <v-card-actions>
                  <v-icon size="22">
                    {{
                      index == 0
                        ? "mdi-checkbox-marked-circle"
                        : "mdi-circle-outline"
                    }}
                  </v-icon>
                </v-card-actions>
                <v-card-title class="pt-1"> 아뇨 없습니다. </v-card-title>
                <v-card-subtitle>
                  3D 도면 설계부터 필요한 경우
                </v-card-subtitle>
                <v-card-text>
                  필요한 자료 <br />
                  - 치수가 표현된 스케치 <br />
                  - 다각도에서 촬영된 이미지 <br />
                  - 사용방법 및 목적 등
                </v-card-text>
              </v-card>
            </v-col>
            <v-col>
              <v-card
                class="mx-5 pa-4"
                rounded="lg"
                height="240px"
                min-width="250px"
                elevation="4"
                @click="haveFile()"
              >
                <v-card-actions>
                  <v-icon size="22">
                    {{
                      index == 1
                        ? "mdi-checkbox-marked-circle"
                        : "mdi-circle-outline"
                    }}
                  </v-icon>
                </v-card-actions>
                <v-card-title class="pt-1"> 파일이 있습니다. </v-card-title>
                <v-card-subtitle> 3D 출력이 바로 가능한 경우 </v-card-subtitle>
                <v-card-text>
                  필요한 자료 <br />
                  - STL, OBJ 3D 파일 <br />
                  - 사용방법 및 목적 등
                </v-card-text>
              </v-card>
            </v-col>
            <v-col>
              <v-card
                class="mx-5 pa-4"
                rounded="lg"
                height="240px"
                min-width="250px"
                @click="showMessenger"
                elevation="4"
              >
                <v-card-actions>
                  <v-icon size="22">
                    {{
                      index == 2
                        ? "mdi-checkbox-marked-circle"
                        : "mdi-circle-outline"
                    }}
                  </v-icon>
                </v-card-actions>
                <v-card-title class="pt-1"> 잘 모르겠습니다. </v-card-title>
                <v-card-subtitle> 담당자의 도움이 필요한 경우 </v-card-subtitle>
                <v-card-text>
                  필요한 자료 <br />
                  - 만들고자 하는 제품의 설명
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-card-text>
  </v-card>
</template>

<script>
import Stepper from "@/components/Assets/Stepper.vue";
export default {
  components: { Stepper },
  data() {
    return { index: 1 };
  },
  methods: {
    noFile() {
      this.index = 0;
      window.ChannelIO("showMessenger");
    },
    haveFile() {
      this.index = 1;
      window.ChannelIO("hideMessenger");
    },
    showMessenger() {
      this.index = 2;
      window.ChannelIO("showMessenger");
    },
    next() {
      if (this.index === 0)
        return window.alert("설계 문의는 바로 채팅 남겨주세요");
      if (this.index === 2) return window.alert("채팅 문의 남겨주세요");
      if (this.index === 1) return this.$emit("changeStep", 1);
    },
  },
};
</script>

<style></style>
