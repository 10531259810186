<template>
  <div class="mt-4">
    <span v-text="label" :style="'margin-left: ' + 52 * currentStep + 'px'" />
    <div class="d-flex mx-3" style="align-self: center">
      <v-icon size="16" class="mx-2" color="black"> mdi-checkbox-marked-circle </v-icon>
      <div
        v-for="n in steps"
        :key="n"
        class="d-flex"
        style="align-self: center"
      >
        <div
          style="width: 20px; height: 2px; align-self: center"
          :style="n <= currentStep ? 'background: black' : 'background: grey'"
        />
        <v-icon v-if="n <= currentStep" size="16" class="mx-2" color="black">
          mdi-checkbox-marked-circle
        </v-icon>
        <v-icon size="16" class="mx-2" v-else> mdi-circle-outline </v-icon>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: { steps: Number, currentStep: Number, label: String },
};
</script>

<style></style>
