<template>
  <v-card max-width="1104px" class="mx-auto" flat>
    <v-card-actions class="mt-5">
      <v-btn large class="pr-4" @click="$router.push('/page')">
        <v-icon size="22"> mdi-chevron-left </v-icon> 홈으로
      </v-btn>
      <v-spacer />
      <v-btn
        large
        color="primary"
        class="pl-4"
        @click="$router.push('/mypage')"
      >
        접수 내역 <v-icon size="22"> mdi-chevron-right </v-icon>
      </v-btn>
    </v-card-actions>
    <v-card-text
      class="pt-15"
      :class="$vuetify.breakpoint.smAndDown ? 'px-5' : 'px-10'"
    >
      <v-card flat>
        <div class="d-flex">
          <stepper
            v-if="!$vuetify.breakpoint.smAndUp"
            class="mb-10 mx-auto"
            :steps="3"
            :currentStep="3"
            :label="'주문 접수'"
          />
        </div>
        <div class="d-flex">
          <div>
            <v-card-title class="display-1 font-weight-bold">
              주문서 업로드
            </v-card-title>
            <v-card-subtitle class="subtitle-1">
              작성된 내용을 서버로 전송합니다.
            </v-card-subtitle>
          </div>
          <v-spacer />
          <stepper
            v-if="$vuetify.breakpoint.smAndUp"
            :steps="3"
            :currentStep="3"
            :label="'주문 접수'"
          />
        </div>
        <v-card-text class="mt-12 onSurface--text">
          <v-row>
            <v-col md="5" sm="6" cols="12">
              <v-card elevation="4" rounded="lg" class="ma-2">
                <div class="d-flex">
                  <v-progress-circular
                    v-if="uploadProgress.final == false"
                    color="primary"
                    indeterminate
                    size="80"
                    width="5"
                    class="mt-8 mx-auto"
                  />
                  <checkmark v-else class="mx-auto mt-8" />
                </div>
                <v-card-title>
                  <span class="mx-auto">
                    주문서 업로드 {{ uploadProgress.final ? "완료" : "중" }}
                  </span>
                </v-card-title>
                <v-card-subtitle class="d-flex">
                  <span
                    v-if="!uploadProgress.form"
                    class="mx-auto"
                    v-text="'주문서 생성중'"
                  />
                  <span
                    v-else-if="!uploadProgress.file == formData.file.length"
                    class="mx-auto"
                    v-text="
                      '첨부파일 업로드 [ ' +
                      uploadProgress.file +
                      ' / ' +
                      formData.file.length +
                      ' ]'
                    "
                  />
                  <span
                    v-else-if="!uploadProgress.final"
                    class="mx-auto"
                    v-text="'주문서 업로드'"
                  />
                </v-card-subtitle>
                <v-divider class="mx-10" />
                <v-card-text class="py-5 px-5">
                  <v-row class="px-5">
                    <v-col cols="4">
                      <span v-text="'접수 명'" />
                    </v-col>
                    <v-col cols="8">
                      <span v-text="formData.purpose" />
                    </v-col>
                    <v-col cols="4">
                      <span v-text="'접수 일시'" />
                    </v-col>
                    <v-col cols="8">
                      <span>
                        {{
                          formData.createdAt
                            | moment(
                              $vuetify.breakpoint.smAndDown
                                ? "YY/MM/DD HH:mm"
                                : "YYYY/MM/DD HH시 mm분"
                            )
                        }}
                      </span>
                    </v-col>
                    <v-col cols="4">
                      <span v-text="'접수자'" />
                    </v-col>
                    <v-col cols="8">
                      <span v-text="formData.displayName" />
                    </v-col>
                    <v-col cols="4">
                      <span v-text="'첨부 파일'" />
                    </v-col>
                    <v-col cols="8">
                      <span v-text="formData.file.length + '개'" />
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col
              md="7"
              sm="6"
              cols="12"
              :class="$vuetify.breakpoint.smAndDown ? 'pt-10' : ''"
            >
              <v-card
                @click="item.show = !item.show"
                v-for="item in contents"
                :key="item.question"
                :ripple="false"
                flat
                active-class="red"
                class="transparent"
              >
                <v-card-title
                  class="font-weight-bold onSurface--text"
                  :class="$vuetify.breakpoint.smAndUp ? 'subtitle-1' : 'body-2'"
                >
                  Q. {{ item.question }}
                  <v-icon class="ml-auto" v-if="item.show">
                    mdi-chevron-up
                  </v-icon>
                  <v-icon class="ml-auto" v-else> mdi-chevron-down </v-icon>
                </v-card-title>
                <v-expand-transition>
                  <div class="surface" v-show="item.show">
                    <v-divider />
                    <v-card-text
                      :class="
                        $vuetify.breakpoint.mdAndUp ? 'body-1' : 'body-2 pb-0'
                      "
                    >
                      <p>
                        <span class="font-weight-bold" v-text="'A.'" />
                        {{ item.answer }}
                      </p>
                    </v-card-text>
                    <v-divider />
                  </div>
                </v-expand-transition>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-card-text>
  </v-card>
</template>

<script>
import firestorageUtils from "@/utils/firestorage";
import Checkmark from "../../../../components/Assets/Checkmark.vue";
import Stepper from "@/components/Assets/Stepper.vue";
export default {
  components: { Stepper, Checkmark },
  props: { formData: Object },
  data() {
    return {
      uploadProgress: { form: false, final: false, file: 0 },
      contents: [
        {
          question: "접수 후 따로 연락을 드려야 하나요?",
          show: true,
          answer:
            "자동으로 생성된 채팅방(우측 하단)으로 담당자가 영업시간 중 답변드립니다! 답변 알림이 문자 또는 알림톡으로 가니, 1644-9672 스팸 여부를 확인해 주세요.",
        },
        {
          question: "주문 옵션 수정도 가능한가요?",
          show: false,
          answer:
            "담당자와 상담 과정에서 옵션 변경 및 파일 수정 모두 가능합니다.",
        },
        {
          question: "주문 취소하고 싶어요.",
          show: false,
          answer:
            "마이페이지 주문내역에서 주문서를 삭제하실 수 있습니다. 주문서 삭제 시 업로드한 파일도 같이 삭제됩니다.",
        },
        {
          question: "작업장마다 견적을 다 받아보고 싶어요.",
          show: false,
          answer:
            "상담을 통합 관리하기에, 따로 추가 접수하지 않으셔도 상담 과정에서 담당자에게 여쭤보시면 됩니다!",
        },
        {
          question: "출력물을 받아보기까지 얼마나 걸리나요?",
          show: false,
          answer:
            "일반적으로 결제 당일 작업이 착수되며, 작업물에 따라 소요 시간이 달라집니다. 결제 시점 담당자에게 한번 더 확인해주세요.",
        },
      ],
    };
  },
  async mounted() {
    //order DB 추가
    let orderID;
    this.formData.createdAt = new Date();
    this.formData.updatedAt = new Date();
    orderID = await this.$axios.put("orders/newOrder/", {
      id: "",
      uid: this.formData.uid,
      displayName: this.formData.displayName,
    });
    this.uploadProgress.form = true;

    //storage File Upload
    for (var i = 0; i < this.formData.file.length; i++) {
      const result = await firestorageUtils.uploadFile({
        file: this.formData.file[i],
        path: `Orders/${orderID.data}/QuoteFile/${this.formData.file[i].name}`,
      });
      this.formData.file[i].filePath = result.filePath;
      this.formData.file[i].downloadURL = result.downloadURL;
      if (this.formData.file[i].previewImg) {
        const blob = await (
          await fetch(this.formData.file[i].previewImg)
        ).blob();
        const result1 = await firestorageUtils.uploadFile({
          file: blob,
          path: `Orders/${orderID.data}/QuoteFile/${this.formData.file[
            i
          ].name.substring(
            0,
            this.formData.file[i].name.lastIndexOf(".")
          )}_Img.png`,
        });
        this.formData.file[i].previewImg = result1.downloadURL;
      }
      this.uploadProgress.file = i + 1;
    }
    const s = await this.$axios.post("notice/orderCreated", {
      user: window.channelUser,
      order: this.formData,
    });
    this.formData.channelTalk = { chat: s.data };
    if (this.formData.workspace == "경희대학교 국제캠퍼스") {
      this.formData.shippingMethod = 5;
      this.formData.paymentMethod = 1;
      this.formData.productCost = 0;
      this.formData.workspaceCost = 0;
    }
    await this.$axios.put("orders/updateOrder/" + orderID.data, {
      form: this.formData,
      file: this.formData.file,
    });
    this.uploadProgress.final = true;
    this.$toasted.global.success("접수 완료");
  },
  methods: {},
};
</script>

<style></style>
