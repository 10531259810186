import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { STLLoader } from "three/examples/jsm/loaders/STLLoader";

export default {
  async getPreviewImg(blob) {
    // Create DOM Element for renderer
    const rendererElement = document.createElement("div");
    rendererElement.style.width = "720px"; // Set the desired dimensions
    rendererElement.style.height = "380px";
    document.body.appendChild(rendererElement);
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = async (event) => {
        const loader = new STLLoader();
        const geometry = loader.parse(event.target.result);
        geometry.rotateX(Math.PI / -2);
        const scene = new THREE.Scene();
        scene.background = new THREE.Color(0xffffff); // Set background color to white
        const aspectRatio =
          rendererElement.offsetWidth / rendererElement.offsetHeight;
        const camera = new THREE.PerspectiveCamera(50, aspectRatio, 0.1, 2000);
        const renderer = new THREE.WebGLRenderer({ antialias: true });
        renderer.setSize(
          rendererElement.offsetWidth,
          rendererElement.offsetHeight,
        );
        rendererElement.innerHTML = "";
        rendererElement.appendChild(renderer.domElement);
        const controls = new OrbitControls(camera, renderer.domElement);
        const parent = new THREE.Object3D(); // Create a parent object
        const mesh = new THREE.Mesh(
          geometry,
          new THREE.MeshPhongMaterial({ color: 0xaecdff }),
        );
        parent.add(mesh); // Add the mesh to the parent object

        const fileBox = new THREE.Box3().setFromObject(parent);
        const center = fileBox.getCenter(new THREE.Vector3());
        const size = fileBox.getSize(new THREE.Vector3()).length();
        const dimension = fileBox.getSize(new THREE.Vector3()); // Get file dimensions
        const file = {
          dimension: {
            x: Math.round(dimension.x),
            y: Math.round(dimension.z),
            z: Math.round(dimension.y),
          },
        };

        const lightColor = 0xffffff;
        const topLight = new THREE.DirectionalLight(lightColor, 0.4);
        topLight.position.set(0, 100, 0);
        topLight.castShadow = true;
        scene.add(topLight);
        const cameraLight = new THREE.DirectionalLight(lightColor, 0.6);
        cameraLight.castShadow = true;
        scene.add(cameraLight);

        parent.position.sub(center);
        parent.position.y += file.dimension.z / 2;

        scene.add(parent);

        // Adjust camera's position
        camera.position.set(size * 0.75, size * 0.75, size * 0.75);
        cameraLight.position.copy(camera.position);
        controls.target.set(0, file.dimension.y / 5, 0);
        controls.update();

        // Render the scene again after hiding the grid
        renderer.render(scene, camera);

        // Get the screenshot data URL
        const previewImg = renderer.domElement.toDataURL("image/png");

        //html element 삭제 및 초기화 코드
        document.body.removeChild(rendererElement);
        const result = { previewImg: previewImg, dimension: file.dimension };

        resolve(result);
      };
      reader.readAsArrayBuffer(blob);
    });
  },
};
