<template>
  <div>
    <div
      class="d-flex"
      style="width: 100%; aspect-ratio: 1.778"
      @click="fileType == '3dModel' && openDialog()"
    >
      <span v-if="fileType == ''" class="mx-auto my-auto">
        지원하지 않는 미리보기
      </span>
      <v-progress-circular
        v-else-if="!previewImg"
        indeterminate
        class="mx-auto my-auto"
        color="primary"
      />
      <v-img v-else :src="previewImg" :aspect-ratio="16 / 9">
        <span class="subtitle-2"> 3D 미리보기 </span>
      </v-img>
    </div>
    <v-dialog v-model="dialog" max-width="960px">
      <model-3-d-viewer-dialog
        v-if="fileType == '3dModel'"
        :dialog="dialog"
        :data="data"
        :file="file"
        @closeDialog="closeDialog"
      />
    </v-dialog>
  </div>
</template>

<script>
import Model3DViewerDialog from "./Model3DViewerDialog.vue";
import threeFunc from "@/utils/threeFunc.js";
export default {
  props: { data: Object, file: File },
  components: { Model3DViewerDialog },
  watch: {
    file: {
      handler() {
        this.checkFileType();
      },
    },
    data: {
      handler() {
        this.checkFileType();
      },
    },
    deep: true,
  },
  data() {
    return {
      dialog: false,
      fileType: "",
      previewImg: "",
    };
  },
  mounted() {
    if (this.data && this.data.previewImg != "") {
      this.fileType = "3dModel";
      this.previewImg = this.data.previewImg;
    } else {
      this.checkFileType();
    }
  },
  methods: {
    openDialog() {
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
    },
    checkFileType() {
      const fileType = this.file
        ? this.file.name
            .substring(
              this.file.name.lastIndexOf(".") + 1,
              this.file.name.length
            )
            .toLowerCase()
        : this.data.filePath
            .substring(
              this.data.filePath.lastIndexOf(".") + 1,
              this.data.filePath.length
            )
            .toLowerCase();
      switch (fileType) {
        case "jpg":
        case "jpeg":
        case "png":
          this.fileType = "image";
          this.file
            ? (this.previewImg = URL.createObjectURL(this.file))
            : (this.previewImg = this.data.downloadURL);
          return;
        case "stl":
        case "obj":
          this.fileType = "3dModel";
          this.show3dModel();
          return;
        default:
          return (this.fileType = "");
      }
    },
    async show3dModel() {
      if (this.file) {
        const s = await threeFunc.getPreviewImg(this.file);
        this.previewImg = s.previewImg;
        this.file.previewImg = this.previewImg;
        this.file.dimension = s.dimension;
        if (
          parseInt(s.dimension.x) +
            parseInt(s.dimension.y) +
            parseInt(s.dimension.z) <
          30
        ) {
          this.$toasted.global.error(
            "모델 크기가 너무 작습니다. mm로 설계된 파일인지 확인 부탁드립니다"
          );
        }
        if (
          parseInt(s.dimension.x) > 400 ||
          parseInt(s.dimension.y) > 400 ||
          parseInt(s.dimension.z) > 400
        ) {
          this.$toasted.global.error(
            "모델 크기가 너무 큽니다. mm로 설계된 파일인지 확인 부탁드립니다"
          );
        }
      } else {
        await fetch(this.data.downloadURL)
          .then((response) => response.blob())
          .then(async (blob) => {
            const s = await threeFunc.getPreviewImg(blob);
            this.previewImg = s.previewImg;
            this.data.previewImg = this.previewImg;
            this.data.dimension = s.dimension;
          })
          .catch((err) => console.error(err));
      }
    },
  },
};
</script>

<style></style>
