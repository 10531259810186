<template>
  <v-container fluid class="pa-0">
    <div
      style="
        width: 100%;
        background: linear-gradient(90deg, #36d1dc 0%, #5b86e5 100%);
      "
      :style="$vuetify.breakpoint.smAndUp ? 'height: 20px' : 'height: 4px'"
    />
    <account-check-before-order />
    <order-3-d-model-check v-if="step == 0" @changeStep="changeStep" />
    <order-3-d-file-upload
      v-if="step == 1"
      @changeStep="changeStep"
      @changeFormData="changeFormData"
      :data="formData"
    />
    <order-form-data-input
      v-if="step == 2"
      @changeStep="changeStep"
      @changeFormData="changeFormData"
      :userData="userData"
      :data="formData"
    />
    <order-form-upload v-if="step == 3" :formData="formData" />
  </v-container>
</template>

<script>
import API from "@/API/auth.js";
import _ from "lodash";
import constants from "@/utils/constants";
import AccountCheckBeforeOrder from "@/components/Dialog/Orders/AccountCheckBeforeOrder.vue";
import Order3DModelCheck from "./Components/Order3DModelCheck.vue";
import Order3DFileUpload from "./Components/Order3DFileUpload.vue";
import OrderFormDataInput from "./Components/OrderFormDataInput.vue";
import OrderFormUpload from "./Components/OrderFormUpload.vue";
import setMeta from "@/utils/setMeta";

export default {
  name: "Order",
  components: {
    AccountCheckBeforeOrder,
    Order3DModelCheck,
    Order3DFileUpload,
    OrderFormDataInput,
    OrderFormUpload,
  },
  data: () => ({
    step: 0,
    formData: {},
    userData: {},
  }),
  beforeCreate() {
    setMeta({
      title: "시제품 주문접수 시스템",
      description: "메이드올의 자동화 제조 서비스 주문 페이지입니다.",
      image:
        "https://firebasestorage.googleapis.com/v0/b/madeall.appspot.com/o/Assets%2FOG%2Forder.png?alt=media&token=29d98da5-7eee-4bc2-be6c-8d1856a75b44",
    });
  },
  async mounted() {
    this.formData = _.cloneDeep(constants.orderFormInterface);
    if (this.$store.getters["auth/user/GET_USER"]) {
      this.userData = _.merge(
        _.cloneDeep(constants.userInterface),
        await API.getStoreUserData()
      );
    }
  },
  methods: {
    changeStep(v) {
      this.step = v;
    },
    changeFormData(v) {
      this.formData = _.cloneDeep(v);
    },
  },
};
</script>
