<template>
  <v-dialog v-model="dialogVisible" persistent max-width="280">
    <v-card rounded="lg">
      <v-card-title class="headline font-weight-bold">
        <span class="mx-auto"> 비회원 주문 안내 </span>
      </v-card-title>
      <v-card-text class="text-center">
        회원가입 및 로그인 후 이용하셔야 마이페이지에서 주문내역 조회 및
        작업 진행상황 조회가 가능합니다.
      </v-card-text>
      <v-card-actions class="px-5 pb-4">
        <v-btn color="primary" @click="goToLoginPage">로그인/가입</v-btn>
        <v-spacer />
        <v-btn @click="closeDialog">계속 ></v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialogVisible: false,
    };
  },
  mounted() {
    if (!this.$store.getters["auth/user/GET_USER"]) this.dialogVisible = true;
  },
  methods: {
    goToLoginPage() {
      this.$router.push("/login");
    },
    closeDialog() {
      this.dialogVisible = false;
    },
  },
};
</script>
